@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Quicksand:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .content-auto {
    content-visibility: auto;
  }
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body, html, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

@font-face{
  font-family: 'Berkshire Swash';
  src: url("./fonts/Berkshire_Swash/BerkshireSwash-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family: 'Quicksand';
  src: url("./fonts/Quicksand/Quicksand-VariableFont_wght.ttf");
  font-weight: normal, bold;
  font-style: normal, italic;
}



.alert {
background-color: #f00;
color: #fff;
  }

.alert-danger {
  border-color: #f00;
  border-width: 2px;
}